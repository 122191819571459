<template>
  <v-checkbox
    :input-value="agreeTermsConditions"
    :ripple="false"
    color="lightBlue"
    @change="change">
    <template #label>
      <div>
        I agree with
        <a
          target="_blank"
          href="https://gosourcery.com/terms-conditions"
          @click.stop>
          the terms and conditions
        </a>
      </div>
    </template>
  </v-checkbox>
</template>
<script>
export default {
  name: 'SignUpAgreeTermsConditions',
  props: {
    agreeTermsConditions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(val) {
      this.$emit('update:agreeTermsConditions', val);
    },
  },
};
</script>
<style scoped lang="scss"></style>
